import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"

const FAQ = () => {
    return (
        <Layout>
            <Navbar />

            <PageBanner
                pageTitle="FAQ" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="FAQ" 
            />

            <div className="ptb-100">
                <div className="container">
                    <div className="faq-accordion">
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q1. What is Kubelink?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Kubelink is a highly scalable, high performance container management service based on Kubernetes that supports Docker containers and allows you to easily run applications on a shared cluster. Kubelink eliminates the need for you to install, operate, and scale your own cluster. With simple API calls, you can launch and stop container-enabled applications.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q2. What is the pricing for Kubelink?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        You pay for computer resources (e.g. CPU and memory usage) you create to store and run your application. You only pay for what you use, as you use it; there are no minimum fees and no upfront commitments.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q3. How do I get started using Kubelink?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Visit our <a href='https://console.kubelink.com/tutorial'>Getting Started</a> page for more information on how to start using Kubelink.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q4. Can I use kubectl?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Yes. You can use kubectl same way as it was your cluster. Kubelink supports native kubernetes API. Visit our <a href='/tutorial'>Getting Started</a> page for more information on how to start using Kubelink.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q5. Does Kubelink support running docker containers as root?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        No. You can run container on any user except 0 (root). You can set user id in DockerFile or in Kubernetes Deployment, see <a href='https://gitlab.com/kubelink/examples/-/blob/master/httpd/httpd-deploy.yaml' target="_blank" rel="noreferrer">example Deployment</a> and also <a href='https://engineering.bitnami.com/articles/running-non-root-containers-on-openshift.html'  target="_blank" rel="noreferrer">Bitnami approach</a>.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. Where is Kubelink hosted?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        Kubelink is hosted on Scaleway Cloud, including Scaleway Kubernetes Kapsule and Scaleway Block Storage.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Q6. Who controls the Kubelink data centers?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Scaleway controls the physical components. To help customers better understand what controls Scaleway has in place and how effectively they are operating, Scaleway publishes a <a href='https://www.scaleway.com/en/security-and-resilience/' target="_blank" rel="noreferrer">Security and resilience report</a>.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
            
            <Footer />
        </Layout>
    );
}

export default FAQ